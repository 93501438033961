<template>
  <div>
    <!-- Error while fetching -->
    <div v-if="error">
      {{ error }}
    </div>

    <!-- Success -->
    <template v-else>
      <span class="global-placeholder-el"></span>
      <ListStandardList
        :total-records="totalRecords"
        :content="listContent"
        :allowed-list-types="allowedListTypes"
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
import type { List } from '../../../components/List/Standard/models';
import type { TeaserList } from '../../../components/Teaser/List/models';
import useWidgetTypeConfig from '../../../composables/useWidgetTypeConfig';
import type { RawAddressbaseListItemFragment } from '../../../gql/fragments/__generated/RawAddressbaseListItem';
import type { RawWidgetConfigPoiDefFragment } from '../../../gql/fragments/__generated/RawWidgetConfigPoiDef';
import { WhlModuleType } from '../../../models/WhlModuleType';
import { WhlPageType } from '../../../models/WhlPageType';

definePageMeta({
  whlModuleType: WhlModuleType.Poi,
  whlPageType: WhlPageType.List,
  //middleware: ['whl-module-type-tracker', 'whl-page-type-tracker'],
});

const { t, d, locale } = useI18n();

const widgetConfig = await useWidgetConfig();

const widgetTypeConfig = useWidgetTypeConfig(
  widgetConfig
) as ComputedRef<RawWidgetConfigPoiDefFragment>;
ensureWhlModuleIsEnabled(widgetConfig, WhlModuleType.Poi);

const searchStore = useSearchStore();
const globalStore = useGlobalStore();
globalStore.setListType(toValue(widgetTypeConfig));

useSeoMeta({
  title: t('poi.search.title'),
});

onMounted(() => {
  searchStore.readStateFromRoute();
});

const listType = computed(() =>
  globalStore.state.listType === 'gallery' ? 'tile' : 'default'
);
const pageSize = computed(() => globalStore.state.pageSize);

const baseFilter = buildPoiBaseFilter(widgetConfig);

const userFilter = searchStore.constructFilter(WhlModuleType.Poi);

// make use of fetch composables, where refs can be passed (and changes to the ref´s values will trigger a new fetch)
const {
  fetching,
  error,
  totalRecords,
  pois: relatedRecords,
} = await fetchPois(
  widgetConfig,
  userFilter,
  baseFilter,
  undefined,
  false,
  pageSize
);

const listContent = computed(
  (): List => ({
    showTitle: widgetConfig.value?.showSearchResultHeader ?? true,
    title: t('poi.search.results.title', totalRecords.value),
    loading: toValue(fetching),
    initialType: listType.value,
    showMap: true,
    items:
      relatedRecords.value?.map(
        (poi: RawAddressbaseListItemFragment): TeaserList => {
          const { transformTodayToSingleLineString: nextDate } =
            transformPoiBusinesstimesData(poi, { t, d, locale });

          const { transformToSingleLineString: location } = transformGeoData(
            computed(() => poi?.geoInfo)
          );

          const { transformToSingleLineString: contact } = transformContactData(
            poi.contact
          );

          const { toBaseImage: image } = mapImxPlatformMedia(poi.image);

          const infoTagItems = [];
          if (nextDate.value) {
            infoTagItems.push({
              icon: 'ion:time-outline',
              text: nextDate.value,
            });
          }
          if (location.value || contact.value) {
            infoTagItems.push({
              icon: 'ion:location-sharp',
              text: location.value || contact.value || '',
            });
          }

          return {
            id: poi.id ?? 0,
            moduleType: poi.__typename as WhlModuleType,
            fig: image.value ?? undefined,
            title: poi.title ?? '',
            to: toValue(buildLinkToDetailsPage(poi.permaLink)),
            infoTags: {
              items: infoTagItems,
            },
            coords: poi.geoInfo?.coordinates
              ? {
                  longitude: poi.geoInfo.coordinates.longitude ?? 0,
                  latitude: poi.geoInfo.coordinates.latitude ?? 0,
                }
              : undefined,
          } satisfies TeaserList;
        }
      ) ?? [],
  })
);

const allowedListTypes = computed(() => {
  return widgetTypeConfig.value?.viewTypes?.map((_) => _.name) ?? [];
});
</script>
